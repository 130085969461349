<script setup lang="ts">
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useCms } from '/~/composables/cms/use-cms'

const { threeDsFaqModalTitle, threeDsFaqModalContent } = useCms()
</script>

<template>
  <base-mdl
    :title="threeDsFaqModalTitle"
    width="xse"
    fullscreen="mobile"
    border-radius="xl"
    layout="v2"
    :padding="false"
    adding="none"
    header-border
  >
    <div
      class="p-6 text-center [&_a]:text-blue-500 [&_a]:underline"
      v-html="threeDsFaqModalContent"
    />
  </base-mdl>
</template>
